export const state = () => ({
    iframeUrl: undefined,
    loading: false,
    error: {}
})

export const mutations = {
    set_data(state, data) {
        state.iframeUrl = data.data.attributes["redirect-url"]
    },

    isMobile() {
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
        ) {
            return true
        } else {
            return false
        }
    },
    set_error(state, data) {
        state.error = data
    },
    set_loading(state, data) {
        state.loading = data
    }
}

export const actions = {
    async getEventBetUrl({ commit }, payload) {
        const userId = this.$auth.user._id
        const nick = this.$auth.user.username.replaceAll("₹fp", "")
        const currency =
            this.$auth.user.currencyType !== "INR"
                ? "HKD"
                : this.$auth.user.currencyType
        const clientId = process.env.EVENT_BET_CLIENTID
        const authType = process.env.AUTH_TYPE
        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.EVENT_BET_CUSTOM_URL,
                url: "/api/Authentication/authenticationCheck",
                data: {
                    clientId,
                    authType,
                    userId,
                    nick,
                    gameCode: payload.gameCode,
                    currency
                }
            })
            if (response.data) {
                commit("set_data", response.data)
            }

            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    }
}
