import encryptor from "~/plugins/encryption.js"
export default ({ store, route }) => {
    if (process.env.setUp === "fps" || route.path === "/user-book") return
    if (window) {
        // if (window && window.fcWidget && window.fcWidget.init) {
        //     window.fcWidget.init({
        //         token:
        //             process.env.setUp === "gb"
        //                 ? "0970f79a-205a-421e-9e03-96503a5e021e"
        //                 : "aa7243bf-7cdf-4d07-a02d-fb9d195bc308",
        //         host: "https://wchat.in.freshchat.com",
        //         externalId: externalId ? externalId : "1234567",
        //         restoredId: restoreId ? restoreId : null
        //     })
        // window.fcWidget.user.get((resp) => {
        //     if (resp && resp.data && resp.data.restoreId) {
        //         store.commit("fairplayLogin/saveRestoreId", {
        //             restoreId: resp.data.restoreId
        //         })
        //     }
        //     window.fcWidget.user.setProperties(userProperties)
        //     window.fcWidget.on("user:created", (resp) => {
        //         const status = resp && resp.status
        //         const data = resp && resp.data
        //         if (status === 200) {
        //             if (data.restoreId) {
        //                 store.commit("fairplayLogin/saveRestoreId", {
        //                     restoreId: data.restoreId
        //                 })
        //             }
        //         }
        //     })
        // })
        // New chat
        // ;(function (d, w, c) {
        //     if (!d.getElementById("spd-busns-spt")) {
        //         var n = d.getElementsByTagName("script")[0]
        //         var s = d.createElement("script")
        //         var loaded = false
        //         s.id = "spd-busns-spt"
        //         s.async = "async"
        //         s.setAttribute("data-self-init", "false")
        //         s.setAttribute("data-init-type", "opt")
        //         s.src =
        //             "https://cdn.in-freshbots.ai/assets/share/js/freshbots.min.js"
        //         s.setAttribute(
        //             "data-client",
        //             "1222081f992e3fb70a8ff643142db5a2566bab6d"
        //         )
        //         s.setAttribute(
        //             "data-bot-hash",
        //             "aa6ee4cdf88ba3b9cc7f4a63e40e0673df227ed0"
        //         )
        //         s.setAttribute("data-env", "prod")
        //         s.setAttribute("data-region", "in")
        //         if (c) {
        //             s.onreadystatechange = s.onload = function () {
        //                 if (!loaded) {
        //                     c()
        //                 }
        //                 loaded = true
        //             }
        //         }
        //         n.parentNode.insertBefore(s, n)
        //     }
        // })(document, window, function () {
        //     window.Freshbots.initiateWidget()
        // {
        //     autoInitChat: false,
        //     getClientParams() {
        //         return {
        //             "cstmr::eml": userProperties.email,
        //             "cstmr::phn": userProperties.phone,
        //             "cstmr::nm": userProperties.firstName
        //         }
        //     }
        // },
        // function (successResponse) {},
        // function (errorResponse) {}
        // })
        let freshchatStatus
        if (sessionStorage.getItem("whatsapp_data")) {
            freshchatStatus = JSON.parse(
                encryptor.decrypt(sessionStorage.getItem("whatsapp_data"))
            ).freshchat
        }
        if (freshchatStatus !== undefined && freshchatStatus === true) {
            const FC = document.createElement("script")
            FC.src = "https://wchat.freshchat.com/js/widget.js"
            document.head.appendChild(FC)
            function initFreshChat() {
                window.fcWidget.init({
                    token: "0970f79a-205a-421e-9e03-96503a5e021e",
                    host: "https://one5commute.freshchat.com"
                })
            }
            function initialize(i, t) {
                let e = i.getElementById(t)
                if (e) {
                    // If the element with ID 't' already exists, you can directly call initFreshChat()
                    initFreshChat()
                } else {
                    // If the element doesn't exist, create the script element and load the Freshchat widget
                    e = i.createElement("script")
                    e.id = t
                    e.async = true
                    e.src = "https://one5commute.freshchat.com/js/widget.js"
                    e.onload = initFreshChat()
                    i.head.appendChild(e)
                }
            }
            function initiateCall() {
                initialize(document, "Freshchat-js-sdk")
            }
            setTimeout(() => {
                initiateCall()
            }, 3000)
        }
    }
}
