export const state = () => ({
    iframeUrl: undefined,
    loading: false,
    userId: "",
    operatorId: "",
    teenpattiIframrUrl: "https://d2.fawk.app/#/splash-screen",
    error: {},
    livecardGameData: []
})

export const mutations = {
    set_data(state, data) {
        state.userId = data._id
        const accessToken = this.$auth.getToken("customLocal")
        if (this.$auth.user.currencyType === "POINTS") {
            state.operatorId = process.env.TEENPATTI_OPERATOR_ID_POINTS
        } else if (this.$auth.user.currencyType === "INR") {
            state.operatorId = process.env.TEENPATTI_OPERATOR_ID_INR
        } else {
            state.operatorId = process.env.TEENPATTI_OPERATOR_ID
        }

        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
        ) {
            state.teenpattiIframrUrl = process.env.TEENPATTI_IFRAME_M_URL
        } else {
            state.teenpattiIframrUrl = process.env.TEENPATTI_IFRAME_D_URL
        }
        state.iframeUrl =
            state.teenpattiIframrUrl +
            "/" +
            accessToken +
            "-" +
            state.userId +
            "/" +
            state.operatorId
    },

    isMobile() {
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
        ) {
            return true
        } else {
            return false
        }
    },
    set_error(state, data) {
        state.error = data
    },
    set_loading(state, data) {
        state.loading = data
    },
    set_data_livecard_game(state, data) {
        state.livecardGameData = data
    }
}

export const actions = {
    async getTeenpattiUrl({ commit }, payload) {
        const userId = this.$auth.user._id
        const url = process.env.APP_URL
        const userName = this.$auth.user.username
        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.TEENPATTI_CUSTOM_URL,
                url: "SportsBook/save",
                data: {
                    ...payload,
                    userId,
                    url,
                    userName
                }
            })
            if (response.data) {
                commit("set_data", response.data)
            }

            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    // Live card Game api
    async getLiveCardGameUrl({ commit }, dataTable) {
        commit("set_data_livecard_game", "")
        let accessToken
        if (this.$auth.loggedIn) {
            accessToken = this.$auth.getToken("customLocal")
        }

        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.CONFIG_V2_API_URL,
                url: "/game/getTableByGame",
                data: {
                    gameName: "Live Card",
                    _accessToken: accessToken
                }
            })
            commit("set_data_livecard_game", response.data.data)
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    }
}
